.user-input-form-container {
  display: flex;
  flex-direction: column;
}

.user-input-form {
  max-width: 93%;
  margin: 1rem;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(52, 52, 52, 0.18);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.prompt-input {
  font-size: var(--font-size-small-md);
  margin: auto;
  max-width: 98%;
}

.submit-button {
  padding: var(--spacing-lg) var(--spacing-xxl);
  margin-left: var(--spacing-md);
  color: var(--color-white);
  background: linear-gradient(
    45deg, 
    var(--color-link), 
    var(--color-input-bg-dark)
  );
}
.submit-button:hover {
  background: linear-gradient(25deg, var(--color-input-bg), var(--color-border-active));
  transform: var(--transform-lift); /* Adds a sliding effect on hover */
}
.submit-button:disabled {
  background: var(--color-button-disabled);
  opacity: 60%;
  cursor: not-allowed;
}
.submit-button:active {
  transform: scale(0.95); /* Makes the button look pressed */
}

/* Message Item */


/* ToDo: Will need to change it so the border is always present just not highlighted */
.message-item {
  padding: var(--spacing-md);
}

.message-item,
.file-item {
  background-color: var(--color-white);
  border-radius: var(--border-radius-sm);
  border-left: 3px solid rgb(120, 120, 120);
  text-align: left;
  transition: transform 0.2s var(--transition-ease),
              z-index 0.2s var(--transition-ease);
  min-width: 19em;
  position: relative;
  cursor: pointer;
}
body.dark-mode .message-item,
body.dark-mode .file-item {
  background-color: var(--color-dark-bg);
  color: var(--color-text-light);
}
.message-item:hover,
.file-item:hover {
  transform:scaleX(1.005);
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.file-item.selected,
.message-item.selected {
  border-left: 3px solid var(--color-border-active);
}

/* Reference Area */
.reference-area {
  display: flex;
  gap: 1.75em;
  border: 1px solid #dddddd0f;
  padding: 12px;
  border-radius: 1em;
  margin-bottom: 20px;
}

.reference-section {
  flex: 1;
}
.reference-section label {
  font-weight: bold;
  margin-bottom: 4px;
  display: block;
}

.reference-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

/* 'Palette' Area */
.selected-item {
  position: relative;
  padding: 8px;
  border-bottom: 1px solid #eeeeee14;
}
.selected-item.left {
  border-bottom-left-radius: 15px;
}
.selected-item.right {
  border-bottom-right-radius: 15px;
}
.selected-item:last-child {
  border-bottom: none;
}

.primary-actions {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
}

.selectors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.selector-group {
  border: 1px solid #dddddd0b;
  padding: 0.7em;
  border-radius: 0.7em;
}

.selector-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
}


/* Error Message */
.error-message {
  color: #e74c3c;
  font-size: var(--font-size-sm);
}

/* Deselect Button */
.deselect-button {
  padding: 5px 5px;
  margin-left: 5px;
  font-size: xx-small;
  background-color: rgba(120, 120, 120, 0.2);
  opacity: 30%;
}
.deselect-button:hover {
  opacity: 1;
  background-color: rgba(231, 76, 60, 0.2);
  border-radius: 4px;
}

/* Footer: Delete Button & Timestamp */
.message-footer {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between button and timestamp */
}

.loading-message {
  font-style: italic;
  color: var(--color-text-muted);
}

.time {
  font-size: var(--font-size-small);
  opacity: var(--opacity-muted);
  text-align: left;
}
.file-date {
  font-size: 0.75rem;
  color: #777;
}

.delete-button {
  padding: 0.25rem 0.5rem;
  color: rgb(83, 83, 83);
  text-align: center;
  align-self: flex-start;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.delete-button:hover {
  background-color: red;
  color: white;
  border-color: red;
}
@media (max-width: 800px) {
  .delete-button {
    font-size: 14px;
    padding: 10px 20px;
  }
}