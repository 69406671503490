.message-history-container {
  margin: var(--spacing-xxxl) auto;
}

.category-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

.category-item {
  border-radius: var(--border-radius-md);
  overflow: hidden;
  box-shadow: var(--box-shadow-sm);
  transition: all 0.3s ease; /* Smooth transition for width and flex-basis */
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: calc(10% - var(--spacing-md)); /* Default to 3 items per row */
  min-width: 12em; /* Prevent collapsing on smaller screens */
  /* max-width: calc(10% - var(--spacing-md)); This would prevent the bottom row from occuping having overly wide categories but then categories stop stretching*/
}
/* create class for the last row that CAN have a max width restriction */
.category-item--expanded {
  flex-basis: 100%;
  width: 100%;
}
.category-item:hover {
  box-shadow: var(--box-shadow-md);
}
.category-item > div {
  padding: var(--spacing-md);
  background-color: rgba(var(--color-primary-rgb), 0.05);
  cursor: pointer;
}

.hidden-flex-item {
  content: "";
  border-radius: var(--border-radius-md);
  overflow: hidden;
  box-shadow: var(--box-shadow-sm);
  transition: all 0.3s ease; /* Smooth transition for width and flex-basis */
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: calc(10% - var(--spacing-md));
  min-width: 12em;
  visibility: hidden;
}

.category-title {
  font-size: var(--font-size-lg);
  font-weight: 1200;
  color: rgb(255, 255, 255);
  margin-bottom: var(--spacing-xs);
  text-shadow: 1px 1px 0px rgb(0, 0, 0);
  border-radius: 15px;
  width: auto;
  padding: 0px 5px;
}

.category-description {
  opacity: 80%;
  text-shadow: 1px 1px 0px rgb(0, 0, 0);
}

.message-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--color-bg-secondary);
  border-top: 1px solid rgba(255, 255, 255, 0.111);
}

/* Messages */ 

.message-item {
  border: 1px solid #ffffff7b;
  border-radius: var(--border-radius-md);
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}
.message-item:hover {
  box-shadow: var(--box-shadow-md);
}
.message-item--selected {
  border-color: var(--color-primary);
}

.message-content {
  padding: var(--spacing-md);
  font-size: 0.9rem;
}

.message-header p:first-child {
  margin-top: 0;  /* Otherwise when CodeHighlighting is run the prompt will be decomposed into paragraphs and the first paragraph's margin's will move the content*/
}

.message-footer {
  justify-content: space-between;
}

.message-timestamp {
  font-size: 0.75rem;
  color: #777;
}

.message-actions {
  display: flex;
  gap: 0.5rem;
}

.error-message {
  color: var(--color-error);
  margin-top: var(--spacing-md);
}

@media (max-width: 768px) {
  .category-title {
    font-size: var(--font-size-medium);
  }

  .category-item {
    padding: var(--spacing-sm) var(--spacing-md);
  }

  .message-list {
    padding: var(--spacing-sm) var(--spacing-xs);
  }
}