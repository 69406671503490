.response-textarea {
  max-width: 95%;
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-md);
  outline: none;
}

.response-textarea:focus {
  border-color: var(--color-border-focus);
  box-shadow: 0 0 var(--shadow-focus-size) var(--color-shadow-focus);
}

/* Markdown Suggested Question Text Styling */
.markdown-questions-for-prompt {
  text-align: left; /* Ensures markdown lists are well aligned */
  font-size: var(--font-size-small-lg);
  padding: var(--spacing-xxl);
  background-color: var(--color-markdown-augmented-bg);
  border-radius: var(--border-radius-lg);
  margin-top: var(--spacing-xl);
  box-shadow: var(--box-shadow-augmented);
  transition: background-color var(--transition-fast);
}
.markdown-questions-for-prompt:hover {
  background-color: var(--color-markdown-hover-bg);
}

.questions-list {
  list-style-type: decimal;
  padding-left: var(--spacing-xl);
}

.question-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--spacing-lg-md);
}

.question-text {
  flex: 1;
  margin-right: var(--spacing-lg);
}

.response-form {
  flex: 1;
  margin: auto;
}