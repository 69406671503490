/* General container for the entire app */
.app-container {
  font-family: var(--font-family-base);
  line-height: 1.5;
  text-align: center;
  font-size: var(--font-size-base);
  display: flex;
  height: 100vh;
  flex-direction: row;
  overflow-y: hidden;
  color: var(--color-text);
  margin: 0 auto;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-sm);
  background: var(--color-background);
}

body.dark-mode .app-container {
  background: var(--color-background-dark);
  color: var(--color-text-light);
  transition: background-color var(--transition-normal), color var(--transition-normal);
}

.container {
  max-height: 93vh;
  margin: var(--spacing-md) auto;
  overflow-y: auto;
  box-shadow: var(--box-shadow-settings);
  border-radius: var(--border-radius-lg-md);
}

.side-by-side {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Heading styles for error or message */
.app-heading {
  font-size: var(--font-size-large);
  font-weight: bold;
  margin-bottom: var(--spacing-sm);
  text-shadow: var(--text-shadow);
}

/* Markdown output styling */
.markdown-output {
  text-align: left;
  font-size: var(--font-size-markdown);
  padding: var(--spacing-sm);
  background-color: var(--color-markdown-bg);
  border-radius: var(--border-radius-sm);
  margin-top: var(--spacing-md);
  box-shadow: var(--box-shadow-deep);
  transition: background-color var(--transition-fast), color var(--transition-fast);
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.markdown-output:hover {
  background-color: var(--color-markdown-hover-bg);
}

/* Link styling for navigation */
.link, .nav-link {
  display: inline-block;
  margin-top: var(--spacing-md);
  padding: var(--spacing-xs) var(--spacing-sm);
  font-weight: bold;
  font-size: var(--font-size-link);
  text-decoration: none;
  color: var(--color-link);
  transition: color var(--transition-fast);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-sm);
  background: var(--color-link-bg);
}

/* Add a subtle animation to the link */
@keyframes link-bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: var(--transform-bounce);
  }
}

.link:hover, .nav-link:hover {
  color: var(--color-link-hover);
  animation: link-bounce var(--animation-duration) ease-in-out;
}

body.dark-mode .link,
body.dark-mode .nav-link {
  background-color: var(--color-link-bg-dark);
  color: var(--color-link-dark);
  transition: background-color var(--transition-normal), color var(--transition-normal);
}

/* Smooth scroll behavior for a more polished navigation */
html {
  scroll-behavior: smooth;
  overflow-y: hidden;
}

/* Common useful containers */
.center-contents {
  width:100%;
  display: flex;
  justify-content: center;
}

/* Left Pane */
.left-pane {
  background-color: var(--color-left-pane-bg);
  background-image: url('../public/hex-vert-bg.png');
  background-blend-mode: darken;
  background-size: cover; /* Ensures the image covers the entire element */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;

  padding: var(--spacing-xs);
  border-right: 0.0625em solid var(--color-border);
  overflow-y: auto;
  flex-direction: column; /* Stack children vertically */
  height: 100%; /* Ensure full height */
}
body.dark-mode .left-pane {
  background: rgba(46, 46, 46, 0.1);
  background-image: url('../public/hex-vert-bg.png');
  background-blend-mode: darken;
  background-size: cover; /* Ensures the image covers the entire element */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;

  color: var(--color-text-light);
  transition: background-color var(--transition-normal), color var(--transition-normal);
  border-right: 0.0625em solid var(--color-border-secondary);
}

/* Right Pane */
.right-pane {
  background-color: var(--color-right-pane-bg);
  background-image: url('../public/thinker-bg.png');
  background-size: cover; /* Ensures the image covers the entire element */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;

  padding: var(--spacing-md);
  overflow-y: auto;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  height: 93%; /* Ensure full height */
}
body.dark-mode .right-pane {
  background: var(--color-background-dark);
  background-image: url('../public/thinker-bg.png');
  background-blend-mode: darken;
  background-size: cover; /* Ensures the image covers the entire element */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;

  
  color: var(--color-text-light);
  transition: background-color var(--transition-normal), color var(--transition-normal);
}

@media (max-width: 1024px) or (orientation: portrait)  {
  .left-pane {
    display: none; /* Hide the left pane */
  }
  .resizer {
    display: none;
  }
}

/* Scrolling */

.scrollable::-webkit-scrollbar {
  width: 9px; /* Set the width of the scrollbar */
}
.scrollable::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}
body.dark-mode .scrollable::-webkit-scrollbar-track {
  background: #161616; /* Color of the scrollbar track */
}
.scrollable::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 3px; /* Roundness of the scrollbar thumb */
}
.scrollable::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

/* Explanations */
.explanation {
  color: var(--color-border-focus);
}

/* Buttons */

/* Base button styles */
.button {
  border: 2px solid transparent; /* Default border */
  font-size: var(--font-size-medium); /* Default font size */
  font-weight: bold;
  cursor: pointer; /* Change cursor on hover */
  transition: all var(--transition-fast); /* Common transition */
}
.button:hover {
  opacity: 0.9;
}
.button.active {
  transform: scale(1.1);
}

/* Text Areas */
.textarea {
  padding: var(--spacing-sm);
  font-family: inherit;
  font-size: var(--font-size-small-lg);
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius);
  background-color: var(--color-input-text);
  color: var(--color-text-light);
  resize: vertical;
  width: 100%;
  transition: border-color var(--transition-speed), 
              box-shadow var(--transition-speed);
}
body.dark-mode .textarea {
  background-color: var(--color-input-text);
}

.textarea:focus {
  border-color: var(--color-border-input);
  box-shadow: 0 0 var(--shadow-focus-size) var(--color-shadow-focus);
  outline: none;
}