/* Workflow Container */
.workflow {
  font-family: var(--font-family-base);
  margin: var(--spacing-md);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  box-shadow: var(--box-shadow);
  /* Expand transition to include border-color for smooth state changes */
  transition: box-shadow var(--transition-duration-fast) ease-in-out,
              background-color var(--transition-duration-fast) ease-in-out,
              border-color var(--transition-duration-fast) ease-in-out;
  background: rgba(255, 255, 255, 0.9);
}
body.dark-mode .workflow {
  background: rgba(40, 40, 46, 0.9);
}

/* Workflow Status States */
.workflow.pending {
  border-left: 5px solid var(--color-border-secondary);
}
.workflow.in-progress {
  border-left: 5px solid var(--color-status-in-progress);
  box-shadow: 0 0 10px rgba(23, 162, 184, 0.2);
}
.workflow.finished {
  border-left: 5px solid var(--color-border-active);
  box-shadow: var(--box-shadow-deep);
}

/* Workflow Headers */
.workflow h1 {
  font-size: var(--font-size-large);
  margin-bottom: var(--spacing-sm);
}
.workflow p {
  margin: var(--spacing-xs) 0;
}

/* Steps Section */
.steps {
  margin-top: var(--spacing-lg);
}
.steps h2 {
  font-size: var(--font-size-medium);
  margin-bottom: var(--spacing-md);
}

/* Individual Step */
.step {
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-border-secondary);
  /* Expand transition so that border changes smoothly as well */
  transition: background-color var(--transition-duration-fast) ease,
              box-shadow var(--transition-duration-fast) ease,
              border-color var(--transition-duration-fast) ease;
  background-color: rgba(255, 255, 255, 0.95);
}
body.dark-mode .step {
  background: var(--color-background-dark);
}

/* Step statuses */
.step.pending {
  border-left: 4px solid var(--color-border-secondary);
}
.step.in-progress {
  border-left: 4px solid var(--color-status-in-progress);
  box-shadow: 0 0 10px rgba(23, 162, 184, 0.2);
}
.step.streaming {
  border-left: 4px solid var(--color-status-streaming);
  box-shadow: 0 0 10px rgba(26, 47, 234, 0.2);
}
.step.finished {
  border-left: 4px solid var(--color-border-active);
  box-shadow: var(--box-shadow-deep);
}
.step.error {
  border-left: 4px solid red;
  box-shadow: var(--box-shadow-deep);
}

.step:hover {
  background-color: var(--color-secondary-bg);
  box-shadow: var(--box-shadow-augmented);
}

/* Step Headers and Content */
.step h3 {
  font-size: var(--font-size-medium);
  margin-bottom: var(--spacing-xs);
}
.step p {
  margin: var(--spacing-xs) 0;
}
.step ul {
  margin-top: var(--spacing-xs);
  padding-left: var(--spacing-sm);
}

/* Status Indicator */
.status {
  flex: 1;
  text-align: right;
  font-size: var(--font-size-small);
}
.status.pending {
  color: var(--color-status-pending);
}
.status.in-progress {
  color: var(--color-status-in-progress);
}
.status.streaming {
  color: var(--color-status-streaming);
}
.status.finished {
  color: var(--color-border-active);
}
.status.error {
  color: red;
}

/* Sites */
.site-container {
  text-align: left;
  margin-right: var(--spacing-md);
}

.site-link {
  color: lightcyan;
  margin-left: 5px;
}

.site-image {
  width: 25px;
  height: 25px;
  margin-right: 1rem;
}


/* Workflow Details */
.workflow-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: var(--spacing-xs);
  margin-bottom: var(--spacing-sm);
}
body.dark-mode .workflow-details {
  border-bottom: 1px solid var(--color-border-secondary);
}

.workflow-name {
  flex: 2;
  font-size: var(--font-size-large);
}

.workflow-id {
  flex: 1;
  font-size: var(--font-size-small);
}

/* Step Details */
.step-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-secondary);
  gap: var(--spacing-md);
}

.step-index {
  flex: 1;
  font-size: var(--font-size-small);
}

.response {
  flex: 2;
  text-align: center;
  font-size: var(--font-size-small);
}

/* Parameters */
.parameters {
  white-space: pre-line; /* Preserves both new lines and wraps the text */
  overflow-wrap: break-word; /* Helps break long words if necessary */
  display: block;
  margin-top: var(--spacing-sm);
  text-align: left;
  padding-left: var(--spacing-sm);
  padding-bottom: var(--spacing-xs);
  margin-bottom: var(--spacing-sm);
  font-size: var(--font-size-small-lg);
}
body.dark-mode .parameters {
  color: var(--color-text-muted);
} 

/* Additional Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.workflow,
.step {
  animation: fadeIn var(--animation-duration) ease-in-out;
}