.centered {
  justify-content: center;
  display: flex;
  white-space: initial; 
}

.centered i {
  margin: 0 0.2em;
}

.logo-bg:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: url('../../../public/ThinkerLogo.png');
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 30%;
  filter: blue(40px)
}
.logo-bg {
  position: relative; 
  z-index: 2;
  margin: 100px 0;
  padding: 70px;
}

.about,
.tutorial {
  padding-left: 1.5rem;
}

.beta-banner {
  width: 100%;
  z-index: 3;
}