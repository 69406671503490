body {
  background-color: var(--color-white);
  color: var(--color-text);
  transition: background-color var(--transition-normal), color var(--transition-normal);
}
body.dark-mode {
  background-color: var(--color-body-bg-dark);
  color: var(--color-white);
}

.settings-container {
  max-width: 800px;
  padding: var(--spacing-xl);
  background: var(--color-settings-bg);
}

/* Headings */
.settings-heading {
  font-size: var(--font-size-xxl);
  font-weight: bold;
  margin: var(--spacing-xl) 0 var(--spacing-md);
  padding-bottom: var(--spacing-sm);
  border-bottom: 1px solid var(--color-border-subtle);
  text-shadow: var(--text-shadow-settings);
}

.settings-label {
  font-size: var(--font-size-lg-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
  padding-top: 10px
}

.settings-subsection {
  padding: 0 20px 15px 15px;
  margin: 15px 0 15px 15px;
  border: 1px solid var(--color-border-secondary);
  background-color: rgba(255, 255, 255, 0.114);
  border-radius: 15px
}
.settings-subsection p {
  margin-block-end: 0.5em;
}

.settings-checkbox {
  transform: scale(1.5);
  accent-color: var(--color-border-active);
}

/* Active link feedback */
.link:active {
  transform: translateY(0);
  background-color: var(--color-link-active-bg);
}

/* Navigation styling with clear separation */
nav {
  margin: var(--spacing-xxl);
  border-bottom: 1px solid var(--color-border-nav);
  padding-bottom: var(--spacing-lg);
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Message settings textarea - enhanced spacing and focus styles */
.message-settings textarea {
  width: 100%;
  min-height: 20px;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  background-color: var(--color-body-bg-dark);
  resize: vertical;
  overflow-y: auto;
  font-family: var(--font-family-base);
  line-height: 1.4;
}
.message-settings textarea:focus {
  border-color: var(--color-border-focus);
  outline: none;
  box-shadow: 0 0 0 2px var(--color-border-focus-transparent);
}