/* ResizablePane.css */

.resizable-container {
  position: relative;
  height: 100%;
}


.resizer {
  width: 7px;
  cursor: col-resize;
  background-color: #101010;
  transition: background-color 0.2s;
  /* Make the resizer take the full height of the container */
  height: 100%;
  /* Position the resizer between the panes */
    /* No need for absolute positioning since it's within a flex container */
    /* Ensure it's clickable */
  user-select: none;
  touch-action: none;
}
@media screen and (max-width: 768px){
  .resizer {
    width: 2%;
  }
}

.resizer:hover,
.resizer.resizing {
  background-color: #aaa;
}

/* Optional: Change cursor during resizing */
.resizing {
  cursor: col-resize;
}

/* Optional: Add visual indicators */
.resizer::before {
  content: '';
  display: block;
  height: 30%;
  margin: auto;
  width: 2px;
  background-color: #888;
  border-radius: 1px;
}
