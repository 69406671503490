.tags-manager {
  margin: 10px auto;
  border: 1px solid #2828286d;
  box-shadow: #111;
  color: #111;
  border-radius: 15px;
  background-color: #6e6e6e20;
}

.tags-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-height: 200px;
  overflow-y: auto;
}

.add-tag-item {
  display: flex;
  align-items: center;
}

.tag-item {
  display: flex;
  align-items: center;
  margin: 0px 1px;
  font-size: var(--font-size-small-lg);
  padding: 0 5px;
  border: 1px solid #9aa2ea;
  border-radius: 15px;
  background-color: #f4f4f4;
}
.tag-item:last-child {
  border-bottom: none;
}

.delete-button {
  background: none;
  border: none;
  color: red;
  font-size: 18px;
  cursor: pointer;
}

.add-button {
  background-color: #007bff00;
  color: white;
  display: block;
  border: 1px solid #f4f4f41c;
  padding: 8px 16px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 25px;
}
.add-button:hover {
  background: linear-gradient(25deg, #202020, #28a745);
  border: none
}

.add-tag-form {
  display: flexbox;
  width: 300px;
  flex-direction: column;
  align-items: center;
  font-size: var(--font-size-small-md);
  border: 1px solid #09914d;
  border-radius: 15px;
  background-color: #e4e4e4;
}

.input-field,
.input-value {
  width: 90%;
  box-sizing: border-box;
  border: 1px solid #472570;
  padding: 7px 10px;
  border-radius: 14px;
  background-color: transparent;
}

input::placeholder {
  color: #000000;
  opacity: 0.33;
}