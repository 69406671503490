.markdown-output {
  text-align: left; /* Ensures markdown lists are well aligned */
  font-size: var(--font-size-markdown);
  padding: var(--spacing-lg);
  background-color: var(--color-markdown-bg);
  border-radius: var(--border-radius-lg);
  margin-top: var(--spacing-sm);
  margin-bottom: var(--spacing-xl);
  box-shadow: var(--box-shadow-deep);
  transition: background-color var(--transition-fast);
}

.markdown-output:hover {
  background-color: var(--color-markdown-hover-bg); /* Subtle lightening on hover */
}

.file-items-container {
  margin-bottom: 2em
}

