.navigation {
  position: sticky;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.70);
  border-bottom: 1px solid #dee2e6;
  border-radius: 15px;
  margin-bottom: 2.5%;
  z-index: 1000;
}
body.dark-mode .navigation {
  background-color: rgba(0, 0, 0, 0.60);
  border-bottom: 1px solid #505254;
}

.nav-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2%;
  margin: 0;
  padding: 0;
}

.nav-link {
  text-decoration: none;
  color: #343a40;
  background-color: red;
  font-weight: 500;
  transition: color 0.3s ease;
}
body.dark-mode .nav-link {
  color: #343a40;
}

.nav-link:hover {
  color: #007bff;
}

.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}
