body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Tool Tip */
div.react-tooltip.core-styles-module_tooltip__3vRRp {
  flex-wrap: wrap;
  opacity: 0.95;
  z-index: 9999;
  white-space: pre-line;
  overflow-wrap: break-word;
  box-sizing: border-box;
  padding: 10px;
  max-width: 600px;
}
@media (max-width: 600px) {
  div.react-tooltip.core-styles-module_tooltip__3vRRp {
    max-width: 90%;
    font-size: 1rem;
  }
}

:root {
  /* Colors */
  --font-family-base: 'Montserrat', sans-serif;
  --color-white: #fff;
  --color-light-bg: #f8f9fa;
  --color-secondary-bg: #e9ecef;
  --color-dark-bg: #222222;
  --color-body-bg-dark: #121212;
  --color-border: #ddd;
  --color-border-secondary: #4f4f4f;
  --color-text: #000000;
  --color-text-dark: #495057;
  --color-text-light: #f2f2f2;
  --color-text-muted: #6c757d;
  --color-background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(236, 236, 236, 0.9) 100%
  );
  --color-background-dark: linear-gradient(
    135deg,
    rgba(22, 22, 22, 0.1) 0%,
    rgba(255, 255, 255, 0.02) 100%
  );
  --color-border-active: #61dafb;
  --color-border-input: #564dd2;
  --color-border-focus: #82e4f3;
  --color-border-nav: rgba(255, 255, 255, 0.2);
  --color-border-subtle: rgba(255, 255, 255, 0.1);
  --color-button-disabled: #999999;
  --color-input-bg: #f5f5f5;
  --color-input-bg-dark: #333333;
  --color-input-text: #333333;
  --color-input-text-dark: #f5f5f5;
  --color-link: #353839;
  --color-link-active-bg: rgba(255, 255, 255, 0.2);
  --color-link-hover: #f093fb;
  --color-link-dark: #5199c6;
  --color-link-bg: rgba(236, 242, 242, 0.9);
  --color-link-bg-dark: rgba(40, 44, 52, 0.9);
  --color-left-pane-bg: #f4f4f4;
  --color-right-pane-bg: #fff;
  --color-markdown-bg: rgba(255, 255, 255, 0.05);
  --color-markdown-augmented-bg: rgba(111, 111, 111, 0.05);
  --color-markdown-hover-bg: rgba(255, 255, 255, 0.1);
  --color-shadow: rgba(0, 0, 0, 0.2);
  --color-shadow-deep: rgba(0, 0, 0, 0.5);
  --color-shadow-augmented: rgba(124, 124, 124, 0.5);
  --color-shadow-focus: #9b9b9b;
  --color-settings-bg: rgba(255, 255, 255, 0.1);
  --shadow-focus-size: 0.325em;
  --color-text-shadow: rgba(0, 0, 0, 0.3);
  --color-status-pending: #767676;
  --color-status-in-progress: #b89317;
  --color-status-streaming: #449bff;

  /* Font Sizes */
  --font-size-small: 0.6875em;
  --font-size-small-md: 0.75em; 
  --font-size-small-lg: 0.8em;
  --font-size-medium-small: 0.9em;
  --font-size-medium: 1em;
  --font-size-large: 1.1em;
  --font-size-lg-md: 1.3em;
  --font-size-xxl: 2.5em;
  --font-size-link: 1.1em;
  --font-size-markdown: 1em;
  
  /* Spacing */
  --spacing-xs: 0.1875em;
  --spacing-sm: 0.3125em;
  --spacing-md: 0.625em;
  --spacing-lg: 0.9375em;
  --spacing-xl: 1.25em;
  --spacing-xxl: 1.5625em;
  --spacing-xxxl: 3.125em;
  
  /* Border Radius */
  --border-radius-sm: 0.3125em;
  --border-radius-md: 0.5em;
  --border-radius-lg: 0.625em; 
  --border-radius-lg-md: 0.75em;
  
  /* Shadows */
  --shadow-hover: 0 0.1875em 0.375em rgba(0, 0, 0, 0.1);
  --box-shadow: 0px 4px 12px var(--color-shadow);
  --box-shadow-deep: inset 0px 0px 15px var(--color-shadow-deep);
  --box-shadow-augmented: inset 0px 0px 15px var(--color-shadow-augmented);
  --box-shadow-settings: 0px 0.25em 0.9375em var(--color-shadow);
  --text-shadow: 0px 4px 6px var(--color-text-shadow);
  --text-shadow-settings: 0px 0.25em 0.5em rgba(0, 0, 0, 0.4);
  
  /* Transitions */
  --transition-duration: 0.5s;
  --transition-duration-fast: 0.3s;
  --transition-fast: 0.3s ease-in-out;
  --transition-normal: 0.5s ease;
  --transition-ease: ease;

  /* Transformations */
  --transform-lift: translateY(-0.1875em); 
  --transform-slide-left: translateX(-0.1875em);

  /* Animation */
  --animation-duration: 0.5s;

  /* Others */
  --transform-bounce: translateY(-2px);
  
  /* Opacity */
  --opacity-muted: 0.75;
  
  /* Background Gradient */
  --background-gradient-light: linear-gradient(
    135deg,
    rgb(232, 232, 232) 0%,
    rgba(255, 255, 255, 0.162) 100%
  );
  --background-gradient: linear-gradient(
    135deg,
    rgba(45, 45, 45, 0.9) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
}
