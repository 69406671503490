/* ProgressBar.module.css */
.progressBarContainer {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 4px;
  margin-top: 10px;
}

.progressBarFill {
  height: 10px;
  background-color: #4caf50;
  border-radius: 4px;
  transition: width 0.3s;
}
