.transaction-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fafafa;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-field {
  background-color: #fff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-field:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.input-error {
  border-color: #dc3545;
}

.form-text {
  font-size: 0.875rem;
  color: #6c757d;
  margin-top: 5px;
}

.error-message,
.success-message {
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

.success-message {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.pay-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.pay-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pay-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.button-loading {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 500px) {
  .transaction-form {
    padding: 15px;
  }

  .pay-button {
    font-size: 0.9rem;
  }
}
