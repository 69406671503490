.file-item {
  border: 1px solid #ddd;
}

.file-item-container {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}

.file-icon {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  color: #555;
  width: 2em; /* Added to maintain a fixed size*/
  text-align: center; /* Aligns the icon to the center*/
}

.file-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.file-name {
  font-weight: bold;
}

.file-date {
  font-size: 0.75rem;
  color: #777;
}

.file-actions {
  display: flex;
  gap: 0.3rem;
}

.file-actions button {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.75rem;
}

.file-content {
  padding: 0.5rem;
  border-top: 1px solid #8383835a;
}

.file-summary {
  margin-bottom: 0.5rem;
}

.file-content-text {
  word-wrap: break-word;
  font-size: 0.8rem;
}

.button {
    background-color: #eee;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 0.75rem;
}

.select-button {
    background-color: #4CAF50;
    color: white;
}

.expand-button {
    background-color: #008CBA;
    color: white;
}