.verify-background:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: url('../../../../public/gates-of-hell-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 20%;
  background-blend-mode: darken;
}
body.dark-mode .verify-background:before {
  opacity: 100%;
}
.verify-background {
  z-index: 2;
}

.message {
  padding-left: 10px
}
.promotion {
  padding: 10px;
  padding-left: 20px;
}

.big {
  font-size: larger;
  background: linear-gradient(125deg, rgb(79, 233, 79), rgb(21, 148, 21));
  -webkit-background-clip: text; /* for Safari and Chrome */
  background-clip: text;
  color: transparent;
}