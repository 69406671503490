.file-upload-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2px;
  padding: var(--spacing-md) var(--spacing-md);
  margin-left: var(--spacing-md);
  color: var(--color-white);
  background: linear-gradient(
    45deg, 
    var(--color-link), 
    var(--color-input-bg-dark)
  );
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all var(--transition-fast);
}
.file-upload-button:hover {
  background: linear-gradient(25deg, var(--color-input-bg), var(--color-border-active));
  transform: var(--transform-lift); /* Adds a sliding effect on hover */
}
.file-upload-button:active {
  transform: scale(0.95); /* Makes the button look pressed */
}

.file-input-label {
  margin-bottom: 5px;
  font-weight: bold;
  cursor: pointer;
}

.file-input {
  /* Hide the input element */
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 0;
}

.upload-progress {
  justify-content: center;
  margin-left: 1rem;
}