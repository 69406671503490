.selector-container {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.selector-tag {
  display: flex;
  align-items: center;
  background-color: var(--tag-background, #f0f0f0);
  border-radius: 4px;
  padding: 5px 10px;
}

.selector-tag-label {
  font-weight: bold;
  margin-right: 5px;
}

.selector-tag-value {
  cursor: pointer;
  margin-right: 10px;
}

.selector-delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: var(--delete-button-color, #ff4d4f);
}

.selector-add-button {
  background-color: var(--add-button-background, #1890ff);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.selector-add-button:hover {
  background-color: var(--add-button-hover, #40a9ff);
}

.input-container {
  display: flex;
  align-items: center;
}

.input {
  padding: 5px;
  border: 1px solid var(--input-border, #d9d9d9);
  border-radius: 4px;
  margin-right: 5px;
  font-size: 1.05rem;
  width: 200px;
}

.selector-input:focus {
  outline: none;
  border-color: var(--input-focus-border, #40a9ff);
}

.selector-submit-button,
.selector-cancel-button {
  padding: 5px 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.selector-submit-button {
  color: var(--submit-button-color, #52c41a);
}

.selector-cancel-button {
  color: var(--cancel-button-color, #ff4d4f);
}
