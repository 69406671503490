.background:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: url('../../../public/gates-of-hell-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 20%;
  background-blend-mode: darken;
}
body.dark-mode .background:before {
  opacity: 100%;
}
.background {
    position: relative; 
    z-index: 2;
}

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  font-family: Arial, sans-serif;
}

.logo {
  position: relative; /* Establishes a positioning context */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.beta-symbol {
  position: absolute; /* Positions the beta symbol relative to .logo */
  color: orange;  
  font-size: 11em;
  transform: rotate(-15deg);
  pointer-events: none; /* Ensures the beta symbol doesn't interfere with other elements */
  z-index: 3;
}

.thinker {
  font-size: 10rem;
  font-family: 'Montserrat';
  color: #bbbbbb;
  text-shadow:
  1px 1px 2px rgb(124, 124, 124),
  0 0 1em rgb(255, 255, 255),
  0 0 0.3em rgb(255, 255, 255);
}
body.dark-mode .thinker {
  text-shadow:
  1px 1px 2px rgb(255, 255, 255),
  0 0 1em rgb(0, 0, 0),
  0 0 0.3em rgb(0, 0, 0);
}
@media (max-width: 1024px) {
  .thinker {
    font-size: 7rem; /* Adjust font size for mobile */
  }
  .beta-symbol {
    font-size: 8rem;
  }
}
@media (max-width: 768px) {
  .thinker {
    font-size: 3rem; /* Adjust font size for mobile */
  }
  .beta-symbol {
    font-size: 4rem;
  }
}

.auth-nav {
  position: absolute;
  top: 10px;
  right: 10px;
}
.auth-nav .link {
  margin: 0 10px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.auth-toggle {
  display: flex;
  margin-bottom: 3px;
}

.toggle-button {
  padding: 10px 20px;
  border: 2px solid #545454;
  background-color: #333; /* Default dark grey */
  color: #707070; /* Default grey text */
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  width: 120px;
  text-align: center;
  border-radius: 5px 5px 0 0; /* Rounded top corners */
  transition: all var(--transition-fast);
}
.toggle-button.active {
  background-color: #87CEEB;
  color: #fff;
  border-color: #87CEEB;
  transform: scale(1.1);
  z-index: 3;
}
.toggle-button:not(:last-child) {
  border-right: none; /* Remove double borders between buttons */
}
.toggle-button:hover {
  background-color: #444; /* Slightly lighter grey when hovering */
  color: #fff;
}

.auth-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid #3434342d;
  padding: 25px;
  border-radius: 15px;
}

.form-group {
  margin-bottom: 15px;
}
.form-group label {
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
  color: #d4d4d4;
}
.form-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
}

button:hover {
  opacity: 0.9;
}

.login-button {
  margin-top: 7%;
  margin-left: 20%;
  margin-right: 20%;
}
.login-button:hover {
  background-color: #cbcbcb;
}

.logout-button {
  margin-top: 20px;
  background-color: #e74c3c; /* Red logout button */
  color: #fff;
}

.logout-button:hover {
  background-color: #c0392b; /* Darker red on hover */
}

.card-container {
  display: flex;
  justify-content: space-between;
  padding: 2%;
}


.card {
  border: 1px solid #959595;
  background-color: #ffffff5f;
  border-radius: 15px;
  padding: 16px;
  margin: 16px;
  flex: 1;
}
body.dark-mode .card {
  border: 1px solid #ccc;
  background-color: #00000076;
}

.card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card h3 {
  margin: 0; /* Remove default margin */
}
.card .emoji {
  margin-left: auto; /* Ensures emojis align to the right */
  font-size: 1.5em;
}
.card .emoji-large {
  font-size: 2em; /* The open hands emoji is a bit small */
}

.red-text {
  color: #c0392b;
}

.version-number {
  margin-left: auto;
  opacity: 0.5;
  padding: 0px 10px;
}

@media (max-width: 768px) {
  .card-container {
      flex-direction: column;
      align-items: center;
  }
}
