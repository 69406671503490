.augmented-container {
  align-items: center; /* Vertically centers the items */
  justify-content: center; /* Horizontally centers the items */
  gap: 10px; /* Space between the content and the button */
  padding: 10px;
  text-align: center;
}

.augment-button {
  padding: var(--spacing-lg);
  margin-left: var(--spacing-md);
  font-size: var(--font-size-small-md);
  color: var(--color-white);
  background: linear-gradient(
    45deg, 
    var(--color-link), 
    var(--color-input-bg-dark)
  );
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all var(--transition-fast);
}
.augment-button:hover {
  background: linear-gradient(90deg, var(--color-input-bg), var(--color-border-active));
  transform: var(--transform-lift);
}
.augment-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.markdown-augmented {
  text-align: left; /* Ensures markdown lists are well aligned */
  font-size: var(--font-size-small-lg);
  padding: var(--spacing-md);
  background-color: var(--color-markdown-augmented-bg);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-augmented);
  transition: background-color var(--transition-fast);
}
.markdown-augmented:hover {
  background-color: var(--color-markdown-hover-bg);
}
